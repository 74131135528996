import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import svgSprite from '@syskit/sico/symbol-defs.svg';

import appInsights from './common/applicationInsights';
import { ErrorScreen } from './components/errorBoundary/errorScreen';
import Spinner, { ClipSpinner } from './components/spinner';
import SvgSpriteRenderer from './components/svgSpriteRenderer/svgSpriteRenderer';
import App from './containers/app';
import { useAsync } from './hooks/useAsync';
import { ClientApplication } from './msal/clientApp';
import { AppClient, ClientConfigDto } from './services/service';
import settings from './settings/settings';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './assets/style/devExtreme/devExtreme.scss';
import './assets/style/devExtreme/devExtremeGlobalOverrides.scss';

import './index.scss';

const AppSettings: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { executeAsync, isLoading, error, data } = useAsync<ClientConfigDto>();

    useEffect(() => {
        const appClient = new AppClient();
        appClient.isAnonymous = true;

        executeAsync(() => appClient.getClientConfig())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <LoadingSpinner />
    }

    if (error) {
        return <ErrorScreen error={error} />
    }

    if (data) {
        settings.setSettings(data);
        appInsights.initialize(settings.AppInsightsConnectionString);
    }

    return <>{children}</>;
}

const MsalInitialize: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { executeAsync, isLoading, error } = useAsync<void>();

    useEffect(() => {
        executeAsync(() => ClientApplication.initialize());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <LoadingSpinner />
    }

    if (error) {
        return <ErrorScreen error={error} />
    }

    return <>{children}</>;
}

const root = createRoot(document.getElementById('app')!);

root.render(
    <AppSettings>
        <MsalInitialize>
            <SvgSpriteRenderer svgContent={svgSprite} />
            <App />
        </MsalInitialize>
    </AppSettings>
)

const LoadingSpinner: React.FC = () => (
    <Spinner text='Loading...'>
        <ClipSpinner size={70} color={'var(--electric-indigo)'} />
    </Spinner>
);